const PACKAGE_PARTTYPE_IDS = {
    AC: '09000',
    BRAKE: '09001',
};

export function determineKitProductByAddQuoteResponse(resp, kitData) {
    let curPrebuiltKitProduct;
    let keyType;

    for (let h = 0; h < kitData.partType.kits.length; h++) {
        keyType = resp.priceAvailabilityParamKey.productKeyType === 'LEGACY' ? 'legacyKey' : 'catalogKey';

        for (let k = 0; k < kitData.partType.kits[h].completeProducts.length; k++) {
            if (
                resp.priceAvailabilityParamKey.itemId === kitData.partType.kits[h].completeProducts[k][keyType].itemId &&
                resp.priceAvailabilityParamKey.groupId === kitData.partType.kits[h].completeProducts[k][keyType].groupId
            ) {
                curPrebuiltKitProduct = kitData.partType.kits[h].completeProducts[k];
                break;
            }
        }
    }

    return curPrebuiltKitProduct;
}

export function getAttributeData(attribute) {
    let attrID;

    if (attribute.attributeType === 'vehicle') {
        attrID = 'Application';
    } else if (attribute.attributeType === 'product') {
        attrID = 'Product';
    }

    return {
        attributeTypeID: attrID,
        attributeID: attribute.attributeID,
        attributeValueID: attribute.attributeValueID,
    };
}

/**
 * Checks a product object to see if store has stock or not
 * @param {Object} product
 * @returns {boolean}
 */
export function isProductOutOfStock(product) {
    const ppAvailability = product.partPriceAvailabilityResponse;

    if (ppAvailability && ppAvailability.stocking && ppAvailability.partAvailabilityList) {
        for (let i = 0; i < ppAvailability.partAvailabilityList.length; i++) {
            if (ppAvailability.partAvailabilityList[i].locationType === 'STORE' && ppAvailability.partAvailabilityList[i].quantityOnHand > 0) {
                return false;
            }
        }
        return true;
    }

    return false;
}

/**
 * partTypeId values cannot be more than 5 characters, this is a catch for
 * some of the long string values that sometimes show up in the data
 * ex. part number searches show the partTypeId value as "SEARCH_RESULTS_PART_TYPE"
 * @param {number or string} partTypeId
 * @returns {number or string}
 */
export function validatePartTypeId(partTypeId) {
    return partTypeId.toString().length < 6 ? partTypeId : 0;
}

/**
 * Gets local/store stocking and qty values for specific product
 * @param {Object} product
 * @returns {{stocking: boolean, localQOH: null or number}}
 */
export function getLocalQtyDetails(product) {
    const availability = product.partPriceAvailabilityResponse;
    const details = {
        stocking: availability.stocking,
        localQOH: null,
    };

    for (let i = 0; i < availability.partAvailabilityList.length; i++) {
        if (availability.partAvailabilityList[i].locationType === 'STORE') {
            details.localQOH = availability.partAvailabilityList[i].quantityOnHand;
        }
    }

    return details;
}

/**
 * Gets data that summarizes stocking information for each part type looked up
 * @param {Array} productList - contains part type objects that each contain product data for that part type
 * @returns {Array} contains result summary objects for each part type
 */
export function getResultSummaryData(productList) {
    const resultSummaryArray = [];
    let countStocking;
    let countNonStocking;
    let countSpecialOrder;
    let countOutOfStock;
    let countDisplayed;
    let availability;
    const newPl = [];

    // kits results do not have same structure as regular product results, forced to map data to most common structure
    productList.forEach((partType) => {
        if (partType.kits) {
            partType.kits.forEach((kit) => {
                kit.completeProducts.forEach((product) => {
                    const partTypeIndex = newPl.findIndex(({ partTypeId }) => partTypeId === product.productOriginDetails);

                    if (partTypeIndex !== -1) {
                        newPl[partTypeIndex].completeProducts.push(product);
                    } else {
                        newPl.push({
                            partTypeId: product.productOriginDetails,
                            completeProducts: [product],
                        });
                    }
                });
            });

            // kits results can sometimes have duplicate products so we want to remove duplicates
            newPl.forEach((part) => {
                part.completeProducts = part.completeProducts.filter(
                    (result, index, self) =>
                        index === self.findIndex((obj) => obj.catalogKey.formattedProductKey === result.catalogKey.formattedProductKey)
                );
            });
        } else {
            newPl.push({
                partTypeId: partType.partTypeId,
                completeProducts: partType.completeProducts,
            });
        }
    });

    for (let plIndex = 0; plIndex < newPl.length; plIndex++) {
        countStocking = 0;
        countNonStocking = 0;
        countSpecialOrder = 0;
        countOutOfStock = 0;
        countDisplayed = -1; // BRD says to hard code this value to -1

        // AC and Brake packages are not to be displayed so we also won't track them.
        // We can only remove this if someday they are removed from the catalog.
        if (newPl[plIndex].partTypeId !== PACKAGE_PARTTYPE_IDS.AC && newPl[plIndex].partTypeId !== PACKAGE_PARTTYPE_IDS.BRAKE) {
            for (let productIndex = 0; productIndex < newPl[plIndex].completeProducts.length; productIndex++) {
                availability = newPl[plIndex].completeProducts[productIndex].partPriceAvailabilityResponse;

                if (!availability.stocking && availability.itemMaster) {
                    countNonStocking += 1;
                }

                if (!availability.itemMaster) {
                    countSpecialOrder += 1;
                }

                if (availability.stocking) {
                    countStocking += 1;

                    if (isProductOutOfStock(newPl[plIndex].completeProducts[productIndex])) {
                        countOutOfStock += 1;
                    }
                }
            }

            resultSummaryArray.push({
                partTypeId: validatePartTypeId(newPl[plIndex].partTypeId),
                countProducts: newPl[plIndex].completeProducts.length,
                countStocking,
                countNonStocking,
                countSpecialOrder,
                countOutOfStock,
                countDisplayed,
                duration: 0,
            });
        }
    }

    return resultSummaryArray;
}

/**
 * This is used for product lookups, add to quote (excluding quick adds),
 * and product view details (excluding this action on quote page).
 * This is similar to buildResultsDetailsObj() however they are accepting data in
 * slightly different formats. Could possibly be refactored in the future to combine them.
 * @param {number} qty - desired product quantity customer chose
 * @param {string} productState - defines the action such as: "ADD_TO_INVOICE", "VW_DETAILS", "REM_FROM_INVOICE"
 * @param {Object} partData - product data that is slightly different than productList data
 * @param {number} partTypeId - alternate part type ID if we want to override productList partTypeId value
 * @returns {Object} result details object containing all properties/values required for logging
 */
export function getResultDetailsObj(qty, productState, partData, partTypeId) {
    let localQOH = null;
    let hubQOH = -1;
    let dcQOH = -1;
    let curQOH;

    const partsAvailable = partData.partPriceAvailabilityResponse.partAvailabilityList;
    const validatedPartTypeId = validatePartTypeId(partTypeId);

    for (let k = 0; k < partsAvailable.length; k++) {
        curQOH = partsAvailable[k].quantityOnHand;
        curQOH = curQOH >= 0 ? curQOH : 0;
        if (partsAvailable[k].locationType === 'STORE') {
            localQOH = curQOH;
        } else if (partsAvailable[k].locationType === 'HUB') {
            hubQOH = curQOH;
        } else if (partsAvailable[k].locationType === 'DISTRIBUTION_CENTER') {
            dcQOH = curQOH;
        }
    }

    return {
        partTypeId: validatedPartTypeId,
        line: partData.legacyKey.groupId,
        item: partData.legacyKey.itemId,
        catalogItemId: partData.catalogKey.itemId,
        fitmentNotes: '', // BRD says always leave this blank
        productState,
        quotedPrice: partData.itemCost,
        desiredQuantity: qty,
        localQOH,
        hubQOH,
        dcQOH,
        onOrder: partData.partPriceAvailabilityResponse.onOrder,
        onBackOrder: partData.partPriceAvailabilityResponse.onBackOrder,
        stocking: partData.partPriceAvailabilityResponse.stocking,
        brandCode: partData.catalogKey.groupId,
    };
}

export function getResultDetailsData(productList, productState, qty) {
    let resultDetails = [];
    let quantity;
    let plIdx;
    let plKitIdx;
    let plProductIdx;

    for (plIdx = 0; plIdx < productList.length; plIdx++) {
        if (productList[plIdx].kits) {
            for (plKitIdx = 0; plKitIdx < productList[plIdx].kits.length; plKitIdx++) {
                for (plProductIdx = 0; plProductIdx < productList[plIdx].kits[plKitIdx].completeProducts.length; plProductIdx++) {
                    if (productList[plIdx].kits[plKitIdx].completeProducts[plProductIdx].partPriceAvailabilityResponse.itemMaster) {
                        quantity = qty || -1;
                        resultDetails.push(
                            getResultDetailsObj(
                                quantity,
                                productState,
                                productList[plIdx].kits[plKitIdx].completeProducts[plProductIdx],
                                productList[plIdx].kits[plKitIdx].completeProducts[plProductIdx].productOriginDetails
                            )
                        );
                    }
                }
            }

            // kits results can sometimes have duplicate products, remove duplicates as requested in RWD-3776
            resultDetails = resultDetails.filter(
                (result, index, self) => index === self.findIndex((obj) => obj.line === result.line && obj.item === result.item)
            );
        } else {
            for (plProductIdx = 0; plProductIdx < productList[plIdx].completeProducts.length; plProductIdx++) {
                if (productList[plIdx].completeProducts[plProductIdx].partPriceAvailabilityResponse.itemMaster) {
                    quantity = qty || -1;

                    resultDetails.push(
                        getResultDetailsObj(quantity, productState, productList[plIdx].completeProducts[plProductIdx], productList[plIdx].partTypeId)
                    );
                }
            }
        }
    }

    return resultDetails;
}

export function buildResultsDetailsObj(part, productState, setLocalAvailability) {
    const ppAvailability = part.partPriceAvailabilityResponse || {};
    const availabilityList = ppAvailability.partAvailabilityList || [];
    const brandCode = !part.catalogKey ? null : part.catalogKey.groupId;
    const catalogItemId = !part.catalogKey ? null : part.catalogKey.itemId;
    let hubQOH = -1;
    let dcQOH = -1;
    let localQOH = null;
    let stocking = null;
    let curQOH;
    let partTypeId;

    if (part.partTypeId) {
        ({ partTypeId } = part);
    } else if (part.itemOrigin === 'CATALOG') {
        partTypeId = part.itemOriginDetails;
    } else {
        partTypeId = 0;
    }

    for (let i = 0; i < availabilityList.length; i++) {
        curQOH = availabilityList[i].quantityOnHand;
        if (curQOH > -1) {
            if (availabilityList[i].locationType === 'STORE') {
                if (setLocalAvailability && localQOH === null) {
                    localQOH = curQOH;
                }
            } else if (availabilityList[i].locationType === 'HUB') {
                if (hubQOH === -1) {
                    hubQOH = curQOH;
                }
            } else if (availabilityList[i].locationType === 'DISTRIBUTION_CENTER') {
                if (dcQOH === -1) {
                    dcQOH = curQOH;
                }
            }
        }
    }

    if (setLocalAvailability) {
        ({ stocking } = ppAvailability);
    }

    return {
        brandCode,
        dcQOH,
        desiredQuantity: part.itemQuantity || part.quantity,
        hubQOH,
        line: part.line || part.legacyKey.groupId,
        item: part.itemNumber || part.legacyKey.itemId,
        catalogItemId,
        localQOH,
        onBackOrder: ppAvailability.onBackOrder,
        onOrder: ppAvailability.onOrder,
        partTypeId: validatePartTypeId(partTypeId),
        productState,
        quotedPrice: part.itemCost,
        stocking,
    };
}
